// src/components/ChatbotSidebar.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config';
import { FaEllipsisV } from 'react-icons/fa';

function ChatbotSidebar() {
  const [chatbots, setChatbots] = useState([]);
  const [showOptions, setShowOptions] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchChatbots = async () => {
      const email = localStorage.getItem('email');

      if (!email) {
        console.error('No email found in localStorage');
        return;
      }

      try {
        const response = await fetch(`${API_BASE_URL}/chatbots?email=${email}`);
        const data = await response.json();

        if (response.status === 404) {
          console.error('No chatbots found for this user');
          setChatbots([]);
        } else {
          setChatbots(data);
        }
      } catch (error) {
        console.error('Error fetching chatbots:', error);
      }
    };

    fetchChatbots();
  }, []);

  const handleChatbotClick = (chatbotId) => {
    navigate(`/chat/${chatbotId}`);
  };

  const handleModifyClick = (chatbotId) => {
    navigate(`/modify-chatbot/${chatbotId}`);
  };

  const handleCreateChatbot = () => {
    navigate('/create-chatbot');
  };

  const handleFunctionEditorChat = () => {
    navigate('/function-editor-chat'); // Navigate to the new combined route
  };

  const toggleOptions = (chatbotId) => {
    setShowOptions((prev) => (prev === chatbotId ? null : chatbotId));
  };

  return (
    // <div className="w-64 bg-gray-900 text-white flex flex-col h-full">
    <div className="w-64 bg-[#2E3B55] text-white flex flex-col h-full"> {/* Changed sidebar background */}

      <div className="p-4 flex items-center">
        <img
          src="https://storage.googleapis.com/rgw-public/logo-02.png"
          alt="Company Logo"
          className="h-12 mr-2"
        />
      </div>

      <div className="flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-900">
        {chatbots.map((chatbot) => (
          <div key={chatbot.id} className="relative group">
            <div
              className="flex items-center px-4 py-2 hover:bg-gray-800 cursor-pointer"
              onClick={() => handleChatbotClick(chatbot.id)}
            >
              <span className="flex-1 truncate font-bold">
                {chatbot.chatbot_name || 'Untitled Chatbot'}
              </span>
              <button
                className="text-gray-400 hover:text-white"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleOptions(chatbot.id);
                }}
              >
                <FaEllipsisV />
              </button>
            </div>
            {showOptions === chatbot.id && (
              <div className="absolute right-0 mt-2 bg-white text-black rounded shadow-lg z-10">
                <button
                  className="block px-2 py-1 hover:bg-gray-200 w-full text-left font-bold text-sm"
                  onClick={() => handleModifyClick(chatbot.id)}
                >
                  Modify
                </button>
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="p-4 border-t border-gray-700 space-y-2">
  <button
    className="w-full bg-[#D9008D] hover:bg-white hover:text-[#D9008D] text-white py-2 px-4 rounded-lg border-2 border-[#D9008D] transition duration-300 ease-in-out font-bold shadow-lg"
    onClick={handleCreateChatbot}
  >
    Create Chatbot
  </button>
  <button
    className="w-full bg-[#D9008D] hover:bg-white hover:text-[#D9008D] text-white py-2 px-4 rounded-lg border-2 border-[#D9008D] transition duration-300 ease-in-out font-bold shadow-lg"
    onClick={handleFunctionEditorChat}
  >
    Open Function Editor and Chat
  </button>
</div>


    </div>
  );
}

export default ChatbotSidebar;

