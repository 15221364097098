// src/components/FunctionEditorChatWrapper.js
import React, { useState } from 'react';
import FunctionEditor from './FunctionEditor';
import Chat from './Chat';
import { API_BASE_URL } from '../config';

function FunctionEditorChatWrapper() {
  const [functions, setFunctions] = useState([
    {
      jsonData: JSON.stringify({
        name: "get_current_weather",
        description: "Get the current weather",
        parameters: {
          type: "object",
          properties: {
            location: { type: "string", description: "The city and state, e.g. San Francisco, CA" },
            format: { type: "string", enum: ["celsius", "fahrenheit"], description: "The temperature unit to use." }
          },
          required: ["location", "format"]
        }
      }, null, 2),
      apiCode: "",
      apiUrl: "",
      isValidJson: true,
      errorMessage: ""
    }
  ]);

  const [chatHistory, setChatHistory] = useState([]);
  const [activeSection, setActiveSection] = useState('chat'); // State to toggle sections


  // Handle changes to the JSON input for each function
  const handleJsonChange = (index, newJsonData) => {
    const updatedFunctions = [...functions];
    updatedFunctions[index].jsonData = newJsonData;
    updatedFunctions[index].isValidJson = true; // Reset validation state on edit
    updatedFunctions[index].errorMessage = "";
    setFunctions(updatedFunctions);
  };

  // Save the newly created function to the backend
  const saveFunctionToBackend = async (func) => {
    try {
      const response = await fetch(`${API_BASE_URL}/saveFunction`, {

        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(func)
      });
      const data = await response.json();
      console.log(data.message); // Display a success message if needed
    } catch (error) {
      console.error("Error saving function:", error);
    }
  };

  // Generate API code and URL for each function, then save it to the backend
  const generateApiCode = (index) => {
    try {
      const parsedData = JSON.parse(functions[index].jsonData);
      const functionSpec = parsedData;
      const requiredParams = functionSpec.parameters.required || [];

      // Generate the API code (optional, since APIs are defined in the backend)
      const apiCodeString = `
from flask import Flask, request, jsonify

app = Flask(__name__)

@app.route('/api/${functionSpec.name}', methods=['POST'])
def ${functionSpec.name}():
    data = request.json
    ${requiredParams.map(param => `
    if '${param}' not in data:
        return jsonify({'error': 'Missing required parameter: ${param}'}), 400
    `).join('')}
    # Logic for ${functionSpec.name}
    return jsonify({'message': 'API for ${functionSpec.name} works!'})

if __name__ == '__main__':
    app.run(debug=True)
      `;

      // Generate the API URL
      const apiUrl = `${API_BASE_URL}/${functionSpec.name}`;


      const updatedFunctions = [...functions];
      updatedFunctions[index].apiCode = apiCodeString;
      updatedFunctions[index].apiUrl = apiUrl; // Store generated API URL
      setFunctions(updatedFunctions);

      // Save function to the backend
      saveFunctionToBackend(functionSpec);
    } catch (error) {
      console.error("Invalid JSON format:", error);
      alert("Invalid JSON format. Please fix the errors and try again.");
    }
  };

  // Validate JSON format
  const validateJson = (index) => {
    try {
      JSON.parse(functions[index].jsonData);
      const updatedFunctions = [...functions];
      updatedFunctions[index].isValidJson = true;
      updatedFunctions[index].errorMessage = "";
      setFunctions(updatedFunctions);
      alert("JSON format is valid!");
    } catch (error) {
      const updatedFunctions = [...functions];
      updatedFunctions[index].isValidJson = false;
      updatedFunctions[index].errorMessage = "Invalid JSON format: " + error.message;
      setFunctions(updatedFunctions);
    }
  };

  // Handle changes to the API input for each function
  const handleApiChange = (index, newApiCode) => {
    const updatedFunctions = [...functions];
    updatedFunctions[index].apiCode = newApiCode;
    setFunctions(updatedFunctions);
  };

  // Add a new function
  const addNewFunction = () => {
    setFunctions([
      ...functions,
      {
        jsonData: JSON.stringify({
          name: "new_function_name",
          description: "Describe the new function",
          parameters: {
            type: "object",
            properties: {
              new_param: { type: "string", description: "Describe the new parameter" }
            },
            required: ["new_param"]
          }
        }, null, 2),
        apiCode: "",
        apiUrl: "",
        isValidJson: true,
        errorMessage: ""
      }
    ]);
  };
  const handleChatClick = () => setActiveSection('chat');
  const handleFunctionsClick = () => setActiveSection('functions');

  return (
    <div className="min-h-screen bg-[#ffe5f6] text-white flex flex-col items-center justify-center p-6">
  
      {/* Buttons to toggle between Chat and Functions */}
      <div className="flex gap-4 mb-6">
        <button
          type="button"
          onClick={handleChatClick}
          className={`py-2 px-4 rounded-lg font-bold transition duration-200 ${activeSection === 'chat' ? 'bg-blue-500' : 'bg-gray-600 hover:bg-gray-700'}`}
        >
          Chat
        </button>
        <button
          type="button"
          onClick={handleFunctionsClick}
          className={`py-2 px-4 rounded-lg font-bold transition duration-200 ${activeSection === 'functions' ? 'bg-blue-500' : 'bg-gray-600 hover:bg-gray-700'}`}
        >
          Add Functions
        </button>
      </div>
  
      {/* Conditional rendering based on selected section */}
      {activeSection === 'chat' ? (
        <Chat chatHistory={chatHistory} setChatHistory={setChatHistory} />
      ) : (
        <>
          {functions.map((func, index) => (
            <FunctionEditor
              key={index}
              func={func}
              index={index}
              handleJsonChange={handleJsonChange}
              generateApiCode={generateApiCode}
              validateJson={validateJson}
              handleApiChange={handleApiChange}
            />
          ))}
          <button
            type="button"
            onClick={addNewFunction}
            className="bg-green-500 text-white py-2 px-4 rounded-lg font-bold hover:bg-green-600 transition duration-200 mt-6"
          >
            Add New Function
          </button>
        </>
      )}
    </div>
  );
  
}

export default FunctionEditorChatWrapper;
