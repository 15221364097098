

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { API_BASE_URL } from '../config';
import { FaPaperPlane } from 'react-icons/fa';


import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import copy from 'copy-to-clipboard';



function ChatWithChatbot() {
  const { id } = useParams(); // Get chatbot ID from the URL
  const [chatbotName, setChatbotName] = useState('');
  const [userMessage, setUserMessage] = useState('');
  const [conversation, setConversation] = useState([]); // Holds the conversation
  const [isTyping, setIsTyping] = useState(false); // Flag to show AI typing
  const [chatbotContent, setChatbotContent] = useState(''); // Holds the chatbot's knowledge base
  const [chatbotTitle, setchatbotTitle] = useState(''); // Holds the chatbot's knowledge base
  const [chatbotuserName, setchatbotuserName] = useState(''); // Holds the chatbot's knowledge base


  const CodeBlock = ({ language, value }) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
      copy(value);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    };

    return (
      <div className="relative">
        <button
          onClick={handleCopy}
          className="absolute top-2 right-2 bg-white text-gray-800 px-2 py-1 rounded-md text-sm hover:bg-gray-100 focus:outline-none"
          aria-label="Copy code to clipboard"
        >
          {isCopied ? 'Copied!' : 'Copy'}
        </button>
        <SyntaxHighlighter style={darcula} language={language}>
          {value}
        </SyntaxHighlighter>
      </div>
    );
  };

 

  useEffect(() => {
    // Fetch chatbot details to get the chatbot name
    const fetchChatbot = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/chatbot/${id}`);
        const data = await response.json();
        if (response.ok && data.user_name && data.chatbot_title && data.chatbot_name && data.content) {
          setChatbotName(data.chatbot_name); // Set chatbot name for display
          setChatbotContent(data.content); // Set chatbot content for system prompt
          setchatbotTitle(data.chatbot_title); // Set chatbot content for system prompt
          setchatbotuserName(data.user_name);

        } else {
          console.error('Chatbot name or chatbot content not found or response error');
        }
      } catch (error) {
        console.error('Error fetching chatbot data:', error);
      }
    };

    fetchChatbot();
  }, [id]);

  // Add this useEffect to log the conversation history whenever it updates
  useEffect(() => {
    console.log("Conversation updated at", new Date().toISOString(), conversation);
  }, [conversation]);

  // Reset conversation function
  const resetConversation = async () => {
    try {
      await fetch(`${API_BASE_URL}/reset_conversation`, {
        method: 'POST',
        credentials: 'include', // Ensure the session cookie is included
        headers: { 'Content-Type': 'application/json' },
      });
      setConversation([]); // Clear conversation on frontend
    } catch (error) {
      console.error('Error resetting conversation:', error);
    }
  };

  const handleChat = async () => {
    if (userMessage.trim() === '') return;
  
    // Add user message to conversation for UI display
    setConversation((prevConversation) => {
      const newConversation = [...prevConversation, { sender: 'User', message: userMessage }];
  
      // Send the entire conversation (with assistant and user messages) to OpenAI
      sendChatToOpenAI(newConversation);
  
      return newConversation; // Return the updated conversation state
    });
  
    setUserMessage(''); // Clear the input field
    setIsTyping(true);  // Show AI typing status
  };
  
  // Modify sendChatToOpenAI to include the entire conversation history
  const sendChatToOpenAI = async (conversationHistory) => {
    try {

        // Ensure chatbotContent is available
        if (!chatbotContent) {
          console.error('Chatbot content is not available');
          setIsTyping(false);
          return;
        }

      const systemPrompt = `
          You are an AI assistant and your name is "${chatbotName}" with access to the following knowledge base:

          "${chatbotContent}"

          Your role is to assist the user by providing accurate, relevant, and context-aware answers using the knowledge base. Always keep the user's needs in focus and adhere to the following guidelines:

          Instructions:
          - Prioritize using the provided knowledge base to answer user questions. Do not provide answers outside this content unless explicitly asked for general information.
          - Format any code snippets using Markdown syntax for code blocks.

          - If the user shares personal details (e.g., preferences, important context), remember these throughout the conversation to provide more relevant responses.
          - If the user asks about something they've mentioned before, retrieve that information from the conversation history for consistency.
          - If the knowledge base does not have an answer, be honest about it, but provide helpful follow-up actions or suggestions.
          - Aim to give helpful, polite, clear, and concise answers that fulfill the user's intent. Avoid being overly verbose unless the user asks for more detailed explanations.
          - Where applicable, give examples or summaries to make complex answers more understandable.
          - if User aksed any query in Hinglish then you response will be also in hinglish, if user asked query in english then resposen will be also in english.
	  - Convert all links into Markdown-style clickable links. Use the text immediately before or after the link as the link text.
          Please assist the user in any way you can within the context of the knowledge base.
          `;

      // Prepare the messages payload for OpenAI API
      const messages = [
        { role: 'system', content: systemPrompt },
        ...conversationHistory.map((msg) => ({
          role: msg.sender === 'User' ? 'user' : 'assistant',
          content: msg.message,
        })),
      ];
  
      // Call OpenAI API with conversation history
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer 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`,
        },
        body: JSON.stringify({
          model: "gpt-4o",  // Or any model you prefer
          messages: messages,
          stream: true,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Error during OpenAI API request');
      }
  
      // Process the streamed response from OpenAI (as in your original code)
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let assistantMessage = '';
  
      const processStream = async () => {
        const { done, value } = await reader.read();


  
        const chunk = decoder.decode(value, { stream: true });
  
        try {
          const lines = chunk.split('\n');
          for (const line of lines) {
            const trimmedLine = line.trim();
        
            // Skip if the line contains "[DONE]"
            if (trimmedLine === 'data: [DONE]' || trimmedLine === '[DONE]') {
              console.log("Stream complete");
              continue; // Skip this line
            }
        
            if (trimmedLine) {
              // Parse only valid JSON lines
              try {
                const jsonData = JSON.parse(trimmedLine.replace(/^data:\s*/, ''));
                if (jsonData.choices && jsonData.choices[0].delta && jsonData.choices[0].delta.content) {
                  assistantMessage += jsonData.choices[0].delta.content;
                }
              } catch (jsonErr) {
                console.error('Error parsing JSON:', jsonErr, 'Line:', trimmedLine);
              }
            }
          }
        } catch (err) {
          console.error('Error processing stream:', err);
        }
                
        
        if (done) {
          setIsTyping(false); // Stop typing indicator
          return;
        }
  
        // Update the conversation in real-time
        setConversation((prevConversation) => {
          const updatedConversation = [...prevConversation];
          const lastMessageIndex = updatedConversation.length - 1;
  
          if (updatedConversation[lastMessageIndex]?.sender === chatbotName) {
            updatedConversation[lastMessageIndex].message = assistantMessage.trim();
          } else {
            updatedConversation.push({ sender: chatbotName, message: assistantMessage.trim() });
          }
          
  
          return updatedConversation;
        });
  



        await processStream();
      };
  
      await processStream();
  
    } catch (error) {
      console.error('Error during chat request:', error);
      setIsTyping(false);  // Stop typing indicator
    }
  };

  const renderers = {
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || '');
      const code = String(children).replace(/\n$/, '');
  
      return !inline && match ? (
        <CodeBlock language={match[1]} value={code} />
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },
  
    // Custom renderer for links
    a: ({ href, children }) => (
      <a
        href={href}
        style={{ color: 'blue', textDecoration: 'underline' }} // Apply green color and underline
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    ),
  };

  return (
    <div className="relative h-screen bg-[#ffe5f6] flex flex-col items-center text-center">
      {/* Company Logo */}
      <div className="absolute top-0 left-0 z-20 ml-2 flex items-center">
        <img
          src="https://storage.googleapis.com/rgw-public/logo-02.png"
          alt="Company Logo"
          className="h-20 mr-4"
        />
      </div>

      {/* Chat Interface */}
      <div className="relative z-10 flex flex-col h-full w-full max-w-4xl mx-auto">
        <h1 className="text-black text-3xl font-extrabold uppercase tracking-wider mb-6 mt-6">
          <span className="text-[#D9008D]">{chatbotTitle}</span>
        </h1>

        {/* Chat box */}
        <div className="bg-white bg-opacity-25 text-black p-4 rounded-lg flex-grow overflow-y-auto shadow-2xl border-2 border-[#A5E5C6] hover:border-pink-600">
          {conversation.map((msg, index) => (
            <div
              key={index}
              className={`flex ${
                msg.sender === 'User' ? 'justify-start' : 'justify-end'
              } mb-5`}
            >
              <div
                className={`max-w-[30rem] p-3 rounded-2xl shadow-lg ${
                  msg.sender === 'User'
                    ? 'bg-white text-left text-pink-600'
                    : 'bg-pink-600 text-left text-white'
                }`}
                style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
              >
                <span
                  className={`font-bold ${
                    msg.sender === 'User' ? 'text-black' : 'text-black'
                  }`}
                >
                  {msg.sender === 'User' ? chatbotuserName : chatbotName}:
                </span>
                <ReactMarkdown
                  children={msg.message.trim()}
                  remarkPlugins={[remarkGfm]}
                  components={renderers}
                  className="leading-relaxed font-semibold"
                />
              </div>
            </div>
          ))}

          {/* AI is typing message */}
          {isTyping && (
            <div className="text-right text-gray-500 italic">
              {chatbotName} is typing...
            </div>
          )}
        </div>

        {/* Input box and Reset Conversation button inline */}
        <div className="flex items-center w-full mt-5 mb-5 space-x-4">
          {/* Input box */}
          <div className="flex-grow relative">
            <input
              type="text"
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleChat();
                }
              }}
              placeholder="Message Daalo"
              className="bg-pink-50 text-black border-2 p-2 rounded-lg w-full pr-10 border-[#A5E5C6] focus:outline-none focus:border-pink-800 transition-colors duration-200 shadow-lg hover:border-pink-600"
            />

            {/* Send Icon inside input box */}
            <button
              onClick={handleChat}
              className="absolute right-3 text-[#D9008D] hover:text-pink-800"
              style={{ top: '50%', transform: 'translateY(-50%)' }}
            >
              <FaPaperPlane size={24} />
            </button>
          </div>

          {/* Reset Conversation button */}
          <button
            onClick={resetConversation}
            className="bg-pink-500 text-white px-4 py-2 rounded-lg hover:bg-white hover:text-[#D9008D] border border-[#D9008D] shadow-lg transition duration-300 flex-shrink-0 w-[10rem] text-sm"
          >
            chat clear Kare
          </button>
        </div>
      </div>
    </div>
  );
  

}

export default ChatWithChatbot;

