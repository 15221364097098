import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config';

function ModifyChatbot() {
  const { id } = useParams(); // Get chatbot ID from the URL
  const navigate = useNavigate(); // Initialize navigate for programmatic routing

  const [chatbotTitle, setChatbotTitle] = useState('');
  const [chatbotName, setChatbotName] = useState('');
  const [userName, setUserName] = useState('');
  const [newContent, setNewContent] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [modalContent, setModalContent] = useState(''); // State for modal textarea
  const [isContentLoaded, setIsContentLoaded] = useState(false); // State to track content loading

  useEffect(() => {
    // Fetch the chatbot content based on the ID
    const fetchChatbot = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/chatbot/${id}`);
        const data = await response.json();
        if (response.ok) {
          console.log('Fetched data:', data);
          setChatbotTitle(data.chatbot_title || '');
          setChatbotName(data.chatbot_name || '');
          setUserName(data.user_name || '');
          setNewContent(data.content || ''); // Set the content to modify
          setIsContentLoaded(true);
        } else {
          console.error('Failed to fetch chatbot data:', data);
        }
      } catch (error) {
        console.error('Error fetching chatbot data:', error);
      }
    };
    fetchChatbot();
  }, [id]);

  const handleModify = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/modify-chatbot/${id}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          chatbot_title: chatbotTitle,
          chatbot_name: chatbotName,
          user_name: userName,
          content: newContent,
        }), // Send modified fields
      });
      if (response.ok) {
        alert('Clone updated successfully!');
        navigate('/'); // Navigate to the homepage after successful update
      } else {
        alert('Failed to update clone.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // Function to handle opening the modal
  const openModal = () => {
    console.log('Opening modal. newContent:', newContent);
    setModalContent(newContent); // Initialize modalContent with newContent
    setIsModalOpen(true);
  };

  // Function to handle closing the modal
  const closeModal = () => {
    console.log('Closing modal. modalContent:', modalContent);
    setNewContent(modalContent); // Update newContent with modalContent
    setIsModalOpen(false);
  };

  return (
    <div className="h-screen bg-[#ffe5f6] flex flex-col items-center justify-center text-center">
      <h1 className="text-black text-4xl font-extrabold uppercase tracking-wider mb-12">
        <span className="text-[#D9008D]">{chatbotTitle || 'Modify Chatbot'}</span>
      </h1>
      {/* <div className="absolute top-0 left-0 z-20 ml-2 flex items-center">
        <img
          src="https://storage.googleapis.com/rgw-public/logo-02.png"
          alt="Company Logo"
          className="h-20 mr-4"
        />
      </div> */}
      <input
        type="text"
        value={chatbotTitle}
        onChange={(e) => setChatbotTitle(e.target.value)}
        placeholder="Clone Ka Title"
        className="bg-pink-50 text-black border border-[#A5E5C6] p-2 rounded-lg w-full max-w-md
                   focus:border-pink-800 focus:outline-none placeholder:text-sm hover:bg-pink-100
                   hover:border-pink-600 mb-4"
      />
      <input
        type="text"
        value={chatbotName}
        onChange={(e) => setChatbotName(e.target.value)}
        placeholder="Chatbot Name"
        className="bg-pink-50 text-black border border-[#A5E5C6] p-2 rounded-lg w-full max-w-md
                   focus:border-pink-800 focus:outline-none placeholder:text-sm hover:bg-pink-100
                   hover:border-pink-600 mb-4"
      />
      <input
        type="text"
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
        placeholder="User Name"
        className="bg-pink-50 text-black border border-[#A5E5C6] p-2 rounded-lg w-full max-w-md
                   focus:border-pink-800 focus:outline-none placeholder:text-sm hover:bg-pink-100
                   hover:border-pink-600 mb-4"
      />
      <textarea
        value={newContent}
        onChange={(e) => setNewContent(e.target.value)}
        placeholder="Clone Mein Kya Likho"
        className="bg-pink-50 text-black border border-[#A5E5C6] p-2 rounded-lg w-full max-w-md
                   focus:border-pink-800 focus:outline-none placeholder:text-sm hover:bg-pink-100
                   hover:border-pink-600"
        rows="6"
      ></textarea>
      <button
        type="button"
        onClick={openModal}
        className={`mt-2 text-blue-500 underline ${!isContentLoaded ? 'opacity-50 cursor-not-allowed' : ''}`}
        disabled={!isContentLoaded}
      >
        Open in Large View
      </button>

      <button
        onClick={handleModify}
        className="bg-[#D9008D] text-white px-6 py-2 rounded-lg hover:bg-white
                   hover:text-[#D9008D] border border-[#D9008D] shadow-lg transition
                   duration-300 mt-6"
      >
        Update Clone
      </button>

{/* Modal for large content view */}
{isModalOpen && (
  <div
    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
  >
    <div className="bg-white p-8 rounded-lg max-w-5xl w-full h-[90vh] overflow-auto">
      <h2 className="text-2xl mb-4">Edit Content in Large View</h2>
      <textarea
        value={modalContent}
        onChange={(e) => setModalContent(e.target.value)}
        className="w-full h-[70vh] p-4 border border-gray-300 rounded-lg focus:outline-none
                   focus:border-pink-800 text-black"
      ></textarea>
      <div className="flex justify-end mt-4">
        <button
          onClick={closeModal}
          className="bg-[#D9008D] text-white px-4 py-2 rounded hover:bg-[#b0006d]
                     transition duration-300"
        >
          Save and Close
        </button>
      </div>
    </div>
  </div>
)}

    </div>
  );
}

export default ModifyChatbot;
