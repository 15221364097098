// src/indexe.js
// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';  // Ensure you're importing Tailwind's compiled CSS
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';

// Create a root element for React 18
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the App component wrapped in Router
root.render(
  <Router>
    <App />
  </Router>
);






