import React from 'react';

function FunctionEditor({ func, index, handleJsonChange, generateApiCode, validateJson, handleApiChange }) {
  return (
    <div className="w-full max-w-5xl bg-gray-800 p-8 rounded-lg shadow-md mb-8">
      {/* Box 1: JSON Input */}
      <h2 className="text-3xl font-bold mb-6">Edit Function Specification</h2>
      <div className="mb-6">
        <textarea
          value={func.jsonData}
          onChange={(e) => handleJsonChange(index, e.target.value)}
          rows={15}
          className={`w-full px-4 py-3 border border-gray-600 rounded-lg bg-black text-white font-mono text-base focus:border-blue-500 focus:outline-none hover:border-blue-300 transition duration-200 ${func.isValidJson ? '' : 'border-red-500'}`}
          required
        />
        {/* Display error message if JSON is invalid */}
        {!func.isValidJson && (
          <p className="text-red-500 mt-2">{func.errorMessage}</p>
        )}
      </div>

      <div className="flex justify-between mb-6">
        <button
          type="button"
          onClick={() => generateApiCode(index)}
          className="bg-blue-500 text-white py-3 px-6 rounded-lg font-bold hover:bg-blue-600 transition duration-200"
        >
          Save Function
        </button>
        <button
          type="button"
          onClick={() => validateJson(index)}
          className="bg-yellow-500 text-white py-3 px-6 rounded-lg font-bold hover:bg-yellow-600 transition duration-200"
        >
          Validate JSON
        </button>
      </div>

      {/* Box 2: Display Generated API */}
      {func.apiCode && (
        <div className="mt-6">
          <h2 className="text-3xl font-bold mb-4">Generated API Code</h2>
          <textarea
            value={func.apiCode}
            onChange={(e) => handleApiChange(index, e.target.value)}
            rows={15}
            className="w-full px-4 py-3 border border-gray-600 rounded-lg bg-black text-white font-mono text-base focus:outline-none"
          />
        </div>
      )}

      {/* Box 3: Display Generated API URL */}
      {func.apiUrl && (
        <div className="mt-6">
          <h2 className="text-2xl font-bold mb-2">Generated API URL</h2>
          <p className="text-blue-300 text-lg">{func.apiUrl}</p>
        </div>
      )}
    </div>
  );
}

export default FunctionEditor;
