
// src/components/Chat.js


import React, { useState, useCallback } from 'react';
import { FaPaperPlane } from 'react-icons/fa';
import { API_BASE_URL } from '../config';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeSanitize from 'rehype-sanitize';

function Chat({ chatHistory, setChatHistory }) {
  const [userMessage, setUserMessage] = useState("");

  const handleMessageChange = (event) => {
    setUserMessage(event.target.value);
  };

  const sendMessage = useCallback(async () => {
    if (!userMessage.trim()) return;

    const newUserMessage = { role: "user", content: userMessage };
    setChatHistory((prev) => [...prev, newUserMessage]);
    setUserMessage("");

    try {
      const response = await fetch(`${API_BASE_URL}/query_chat`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          message: userMessage,
          chatHistory: [...chatHistory, newUserMessage],
        }),
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let assistantMessage = "";

      // Add an initial empty assistant message
      setChatHistory((prev) => [
        ...prev,
        { role: "assistant", content: "" },
      ]);

      const processChunk = (chunk) => {
        const lines = chunk.split("\n");
        lines.forEach((line) => {
          if (line.startsWith("data: ")) {
            const cleanLine = line.replace(/^data: /, "").trim();
            assistantMessage += cleanLine + " ";
            setChatHistory((prev) => {
              const updatedHistory = [...prev];
              updatedHistory[updatedHistory.length - 1] = {
                role: "assistant",
                content: assistantMessage.trim(),
              };
              return updatedHistory;
            });
          }
        });
      };

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });
        processChunk(chunk);
      }
    } catch (error) {
      setChatHistory((prev) => [
        ...prev,
        {
          role: "assistant",
          content: "An error occurred. Please try again later.",
        },
      ]);
    }
  }, [userMessage, chatHistory, setChatHistory]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      sendMessage();
    }
  };

  const resetConversation = () => {
    setChatHistory([]);
  };

  return (
    <div className="flex-1 flex flex-col justify-between p-8 w-full max-w-5xl mx-auto">
      {/* Chat History */}
      <div className="bg-white bg-opacity-25 text-black p-4 rounded-lg flex-grow overflow-y-auto shadow-2xl border-2 border-[#A5E5C6] hover:border-pink-600 mb-4 w-full">
        <div className="flex flex-col space-y-5 max-h-[70vh] overflow-y-auto">
          {chatHistory.map((chat, index) => (
            <div
              key={index}
              className={`flex ${
                chat.role === "user" ? "justify-start" : "justify-end"
              }`}
            >
              <div
                className={`max-w-[30rem] p-3 rounded-2xl shadow-lg ${
                  chat.role === "user"
                    ? "bg-white text-left text-pink-600"
                    : "bg-pink-600 text-white"
                }`}
              >
                <span className="font-bold">
                  {chat.role === "user" ? "You" : "Assistant"}:
                </span>{" "}
                {chat.role === "assistant" ? (
                  <ReactMarkdown
                    children={chat.content}
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeSanitize]}
                    components={{
                      p: ({ node, ...props }) => (
                        <p style={{ whiteSpace: "pre-wrap" }} {...props} />
                      ),
                    }}
                  />
                ) : (
                  <span>{chat.content}</span>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Input and Clear Chat Button */}
      <div className="flex items-center w-full space-x-4">
        {/* Input box */}
        <div className="flex-grow relative w-full">
          <input
            type="text"
            value={userMessage}
            onChange={handleMessageChange}
            onKeyDown={handleKeyDown}
            placeholder="Message Daalo"
            className="bg-pink-50 text-black border-2 p-3 rounded-lg w-full pr-12 border-[#A5E5C6] focus:outline-none focus:border-pink-800 transition-colors duration-200 shadow-lg hover:border-pink-600"
          />

          {/* Send Icon inside input box */}
          <button
            onClick={sendMessage}
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-[#D9008D] hover:text-pink-800"
          >
            <FaPaperPlane size={24} />
          </button>
        </div>

        {/* Clear Chat Button */}
        <button
          onClick={resetConversation}
          className="bg-pink-500 text-white px-4 py-3 rounded-lg hover:bg-white hover:text-[#D9008D] border border-[#D9008D] shadow-lg transition duration-300 flex-shrink-0 w-[10rem] text-sm"
        >
          chat clear kare
        </button>
      </div>
    </div>
  );
}

export default Chat;
